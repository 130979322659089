<template>
  <b-card no-body class="p-1">
    <b-row>
      <b-col md="2" sm="4" class="my-1">
        <b-form-group class="mb-0">
          <label class="d-inline-block text-sm-left mr-50">Per page</label>
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            :options="pageOptions"
            class="w-50"
          />
        </b-form-group>
      </b-col>
      <b-col md="4" sm="8" class="my-1">
        <b-form-group
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          label-for="sortBySelect"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-select
              id="sortBySelect"
              v-model="sortBy"
              :options="sortOptions"
              class="w-75"
            >
              <template v-slot:first>
                <option value="">-- none --</option>
              </template>
            </b-form-select>
            <b-form-select
              v-model="sortDesc"
              size="sm"
              :disabled="!sortBy"
              class="w-25"
            >
              <option :value="false">Asc</option>
              <option :value="true">Desc</option>
            </b-form-select>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col md="6" class="my-1 d-flex justify-content-end align-items-center">
        <b-form-group
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          label-for="filterInput"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filterInput"
              v-model="filter"
              type="search"
              placeholder="Type to Search"
            />
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">
                Clear
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
        <b-button
          size="sm"
          class="ml-1 btn btn-success"
          v-b-modal.modal-login
          @click="routeToAddAssignment"
        >
          Add New
        </b-button>
      </b-col>

      <b-col cols="12">
        <b-table
          striped
          hover
          responsive
          :per-page="perPage"
          :current-page="currentPage"
          :items="assignments"
          :fields="tableFields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
        >
          <template #cell(created_at)="data">
            {{ moment(data.created_at).format("MMMM Do YYYY, h:mm:ss a") }}
          </template>
          <template #cell(status)="data">
            <b-badge :variant="assignmentStatus[data.item.status]">
              {{ data.item.status }}
            </b-badge>
          </template>

          <template #cell(actions)="data">
            <div class="d-flex justify-content-center">
              <b-button
                v-if="data.item.status === 'New'"
                size="sm"
                variant="primary"
                @click="handleMakeActive(data.item)"
              >
                Make Active
              </b-button>
              <b-button
                size="sm"
                variant="warning"
                class="ml-1"
                @click="handleSeeMore(data)"
              >
                See More
              </b-button>
            </div>
          </template>
        </b-table>
      </b-col>

      <b-col cols="12">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="center"
          size="sm"
          class="my-0"
        />
        <!-- <button @click="emitCustomEvent">Emit Event</button> -->
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
import {
  BBadge,
  BButton,
  BCard,
  BCol,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BPagination,
  BRow,
  BTable,
} from "bootstrap-vue"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
import { required } from "@validations"
import axios from "@/libs/axios"
import errorResponse from "@/libs/axiosError"

export default {
  components: {
    BRow,
    BCol,
    BTable,
    BBadge,
    BCard,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
  },
  props: {
    assignments: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    modalShow: false,
    perPage: 5,
    required,
    pageOptions: [3, 5, 10],
    totalRows: 1,
    currentPage: 1,
    sortBy: "",
    sortDesc: false,
    sortDirection: "asc",
    filter: null,
    filterOn: [],
    tableFields: [
      { key: "title", label: "Title", sortable: true },
      { key: "client.name", label: "Client", sortable: true },
      {
        key: "created_at",
        label: "Added On",
        sortable: true,
      },
      {
        key: "status",
        label: "Status",
        sortable: true,
        filterOn: true,
      },
      { key: "actions", label: "Actions", thClass: "text-center" },
    ],
    assignmentStatus: {
      Active: "light-primary",
      New: "light-info",
      "Payment Due": "light-danger",
      Archived: "light-dark",
      Complete: "light-success",
    },
    candidateTypeOption: ["Executive", "Expert"],
    countryOption: [],
    functionsOption: [],
    subFunctionsOption: [],
    industriesOption: [],
    clientsOption: [],
    nextTodoId: 2,
    fields: [{ id: 1, content: "" }],
    fieldCount: 1,
  }),
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }))
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.assignments.length
    this.getClients()
  },
  methods: {
    addField() {
      // eslint-disable-next-line no-plusplus
      this.fieldCount++
      this.fields.push({ id: this.fieldCount, content: "" })
    },
    removeField(id) {
      this.fields = this.fields.filter((obj) => obj.id !== id)
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    getSelected(option) {
      return (option && option.name) || ""
    },
    getOptionValue(option) {
      return (option && option.name) || ""
    },
    handleSeeMore(data) {
      console.log(data)
      this.$router.push({
        path: `/details-engagement/${data.item.id}`,
      })
    },
    async getClients() {
      axios
        .get(`/get-clients`)
        .then((res) => {
          this.clientsOption = res.data.clients
        })
        .catch((error) => {
          console.log(error)
          errorResponse(error, this.$router)
        })
    },
    handleMakeActive(data) {
      this.$bvModal
        .msgBoxConfirm(
          "Please confirm that you want to make Assignment Active.",
          {
            title: "Please Confirm",
            size: "sm",
            okVariant: "primary",
            okTitle: "Yes",
            cancelTitle: "No",
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            axios
              .put(`change_astatus/${data.id}`, { status: "Active" })
              .then(() => {
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: `Assignment Status`,
                    icon: "CoffeeIcon",
                    variant: "success",
                    text: `You have successfully Changed Assignment Status!`,
                  },
                })

                this.$emit("custom-event-getAssignment")
              })
              .catch((error) => {
                console.log(error)
                errorResponse(error, this.$router)
              })
          }
        })
    },
    routeToAddAssignment() {
      this.$router.push({
        path: `/add-assignment`,
      })
    },
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
<style scoped>
.actions-header {
  margin: auto;
  font: orange;
}
.float_right {
  float: right;
}
</style>
